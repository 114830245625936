const { useState } = require("react")

const useChartSizes = () => {

  const SIZES = {
    'Rectangle': {
      width: 'max(85vmin, 700px)',
      height: 'max(62vmin, 500px)'
    },
    'Square': {
      width: 'max(62vmin, 500px)',
      height: 'max(62vmin, 500px)'
    },
    'Fullwidth': {
      width: '100%',
      height: 'max(62vmin, 500px)'
    },
  }

  const [currentChartSize, setCurrentChartSize] = useState(Object.keys(SIZES)[0])

  return {
    currentChartSize,
    setCurrentChartSize,
    SIZES
  }
}

export default useChartSizes