import * as React from "react"
import Layout from "../components/layout"
import useOMD from "../components/hooks/useOMD"
import useAxesToPlotSelector from "../components/axes-to-plot-selector"
import useDataLegendSelector from "../components/hooks/use-data-legend-selector"
import useAxesTypeSelector from '../components/hooks/use-axes-type-selector'
import loadable from "@loadable/component"
import useChartSizes from "../components/hooks/use-chart-sizes"
import Seo from "../components/seo"

const Loading = ({ item }) => (
  <>
    <p className="title is-5">Loading {item}...</p>
    <progress className="progress is-small is-primary" max="100">15%</progress>
  </>
)

const Chart = loadable(
  () => import("../components/chart"),
  { fallback: <Loading item='chart'></Loading> }
)

const Table = loadable(
  () => import("../components/table"),
  { fallback: <Loading item='table'></Loading> }
)

const DatasetFilters = loadable(
  () => import("../components/dataset-filters"),
  { fallback: <Loading item='filters'></Loading> }
)

const RODatabasePage = ({ location }) => {


  const { dataset, datasetSchema, filters, datasetSize, isQuerying, setIsQuerying } = useOMD(location)
  const { AxesToPlotSelector, currentAxesToPlot } = useAxesToPlotSelector(datasetSchema)
  const { DataLegendSelector, currentDataLegendSelected } = useDataLegendSelector(datasetSchema)
  const [highlighted, setHighlighted] = React.useState([])
  const { AxesTypeSelector: XAxesTypeSelector, axesTypeSelected: xAxesTypeSelected } = useAxesTypeSelector()
  const { AxesTypeSelector: YAxesTypeSelector, axesTypeSelected: yAxesTypeSelected } = useAxesTypeSelector()
  const { currentChartSize, setCurrentChartSize, SIZES } = useChartSizes()

  return (
    <Layout>
      <Seo title='Reverse Osmosis Database from the Open Membrane Database' />
      <div className="section">
        <div className="container">
          <DatasetFilters
            datasetSchema={datasetSchema}
            updateFiltersState={filters.update}
            removeFilterFromState={filters.remove}
            AxesToPlotSelector={AxesToPlotSelector}
            DataLegendSelector={DataLegendSelector}
            XAxesTypeSelector={XAxesTypeSelector}
            YAxesTypeSelector={YAxesTypeSelector}
            datasetSize={datasetSize}
            filteredDatasetSize={dataset && dataset.length}
            isQuerying={isQuerying}
            setIsQuerying={setIsQuerying}
            useChartSizes={{
              currentChartSize,
              setCurrentChartSize,
              SIZES
            }}
          />
        </div>
      </div>
      <div className="section py-0">
        <div className="container">
          <Chart
            dataset={dataset}
            axesToPlot={currentAxesToPlot}
            currentDataLegendSelected={currentDataLegendSelected}
            currentChartSizes={SIZES[currentChartSize]}
            setHighlighted={setHighlighted}
            xAxesTypeSelected={xAxesTypeSelected}
            yAxesTypeSelected={yAxesTypeSelected}
          />
          <div className='notification'>Some of the A &amp; B  values are calculated based on <a href="/concentration-polarization">assumptions regarding CP modulus</a></div>
        </div>
      </div>
      <div className="container">
      </div>
      <div className="section">
        <div className="container">
          <Table
            dataset={dataset}
            datasetSchema={datasetSchema}
            highlighted={highlighted}
            isQuerying={isQuerying}
          />
        </div>
      </div>
    </Layout>
  )
}

export default RODatabasePage