import React, { useEffect, useState, useCallback } from "react"

const allAxesToPlot = [
    {
        x: "data.a",
        y: "data.ab",
        xSchema: "membranes",
        ySchema: "membranes",
        label: "A/B vs A",
        value: "ABvsA"
    },
    {
        x: "data.a",
        y: "data.robs",
        xSchema: "membranes",
        ySchema: "membranes",
        label: "Robs vs A",
        value: "RobsvsA"
    },
    {
        x: "data.a",
        y: "data.rreal",
        xSchema: "membranes",
        ySchema: "membranes",
        label: "Rreal vs A",
        value: "RrealvsA"
    },
    /* {
        x: "data.ps",
        y: "data.pwPs",
        xSchema: "membranes",
        ySchema: "membranes",
        label: "Pw/Ps vs Ps",
        value: "PwPsvsPs"
    } */
]

const usePlotBy = (datasetSchema) => {

    const [current, setCurrent] = useState({})

    const setCurrentAxesToPlot = useCallback(
        (value) => {

            const [axesToPlot] = allAxesToPlot.filter(axes => axes.value === value)

            let xLabel, yLabel
            const { short: xShort, shortHtml: xShortHtml, unit: xUnit, unitHtml: xUnitHtml } = datasetSchema && datasetSchema[axesToPlot.xSchema][axesToPlot.x].labels
            const { short: yShort, shortHtml: yShortHtml, unit: yUnit, unitHtml: yUnitHtml } = datasetSchema && datasetSchema[axesToPlot.xSchema][axesToPlot.y].labels

            xLabel = `<b>${xShortHtml ? xShortHtml : xShort}</b>` + (xUnitHtml ? ` (${xUnitHtml})` : xUnit ? ` (${xUnit})` : '')
            yLabel = `<b>${yShortHtml ? yShortHtml : yShort}</b>` + (yUnitHtml ? ` (${yUnitHtml})` : yUnit ? ` (${yUnit})` : '')

            setCurrent({
                ...axesToPlot,
                xLabel: xLabel,
                yLabel: yLabel
            })
        },
        [datasetSchema]
    )

    useEffect(
        () => {
            if (datasetSchema) {
                setCurrentAxesToPlot(allAxesToPlot[0].value)
            }
        },
        [datasetSchema, setCurrentAxesToPlot]
    )

    const AxesToPlotSelector = () => {

        return (
            datasetSchema
                ? <select onChange={e => setCurrentAxesToPlot(e.target.value)} value={current.value}>
                    {
                        allAxesToPlot.map(axes =>
                            <option value={axes.value} key={axes.label}>
                                {
                                    datasetSchema[axes.ySchema][axes.y].labels.short
                                    + " vs "
                                    + datasetSchema[axes.xSchema][axes.x].labels.short
                                }
                            </option>
                        )
                    }
                </select>
                : null
        )
    }


    return {
        AxesToPlotSelector: AxesToPlotSelector,
        currentAxesToPlot: current,
    }
}
export default usePlotBy