import React from "react"

const options = [
    'log',
    'linear'
]

const useAxesTypeSelector = () => {

    const [axesTypeSelected, setAxesTypeSelected] = React.useState(options[0])

    const AxesTypeSelector = () => {

        return (
            <select onChange={e => setAxesTypeSelected(e.target.value)} value={axesTypeSelected}>
                {
                    options.map(option =>
                        <option value={option} key={option}>
                            {
                                option
                            }
                        </option>
                    )
                }
            </select>
        )
    }

    return {
        AxesTypeSelector,
        axesTypeSelected
    }

}

export default useAxesTypeSelector