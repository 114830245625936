import React, { useState } from "react"

const allLegends = [
    {
        path: 'chemistry',
        schema: 'membranes',
        key: 'chemistry',
        type: 'string',
        accessor: ''
    },
    {
        path: 'structure',
        schema: 'membranes',
        key: 'structure',
        type: 'string',
        accessor: ''
    },
    {
        path: "publicationDate",
        schema: 'reports',
        key: "publicationDate",
        type: 'date',
        accessor: 'report'
    }
]

const useDataLegendSelector = (datasetSchema) => {

    const [legendSelected, setLegendSelected] = useState(allLegends[0])

    const setCurrentLegend = (e) => {
        const [legend] = allLegends.filter(legend => legend.path === e.target.value)
        setLegendSelected(legend)
    }

    const DataLegendSelector = () => {

        return (
            datasetSchema
                ?
                <select onChange={setCurrentLegend} value={legendSelected.path}>
                    {
                        allLegends.map(legend =>
                            <option value={legend.path} key={legend.path}>
                                {
                                    datasetSchema[legend.schema][legend.path].labels.long
                                }
                            </option>
                        )
                    }
                </select>
                :
                null
        )
    }

    return {
        DataLegendSelector,
        currentDataLegendSelected: legendSelected
    }
}

export default useDataLegendSelector